import { auditFields } from '@wings/shared';

export const fields = {
  serialNumber: {
    label: 'Serial Number*',
    rules: 'required|string|between:0,70',
  },
  manufactureDate: {
    label: 'Manufacture Date',
    rules: 'string',
  },
  temporaryEngineDate: {
    label: 'Temporary Engine Date',
    rules: 'string',
  },
  isVerificationComplete: {
    label: 'Verification Complete*',
    rules: 'required',
  },
  crewSeatCap: {
    label: 'Crew Seat Cap',
    rules: 'numeric|min:0|max:99',
  },
  paxSeatCap: {
    label: 'Pax Seat Cap',
    rules: 'numeric|min:0|max:99',
  },
  genericAircraftCode: {
    label: 'Generic Aircraft Code',
    rules: 'string|between:0,50',
  },
  noiseChapter: {
    label: 'Noise Chapter',
  },
  airframeStatus: {
    label: 'Airframe Status*',
    rules: 'required',
  },
  aircraftVariation: {
    label: 'Aircraft Variation*',
    rules: 'required',
  },
  accessLevel: {
    label: 'Access Level*',
    rules: 'required',
  },
  sourceType: {
    label: 'Source Type',
  },
  status: {
    label: 'Status*',
    rules: 'required',
  },
  airworthinessRecentDate: {
    label: 'Airworthiness Date (Most Recent)*',
  },
  airworthinessCertificateDate: {
    label: 'Airworthiness Date (1st Individual Certificate of Airworthiness)*',
  },
  ...auditFields,
};
